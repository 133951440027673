export enum Experiments {
  PrivateMemberIndicator = 'specs.membersArea.PrivateMemberIndicator',
  WorkingReportMemberFeature = 'specs.membersArea.membersListReportMember',
  UseWarmupDataInProfile = 'specs.membersArea.UseWarmupDataInProfile',
  SettingsTextTab = 'specs.profileCardOOI.settingsTextTab',
  MobileSettingsDesignTab = 'specs.profileCardOOI.designTabMobile',
  DesktopStylesOnMobile = 'specs.profileCardOOI.desktopColorsOnMobile',
  ShowRoleLabelToggle = 'specs.profileCardOOI.roleLabelToggle',
  EnableCornerRadiusSettings = 'specs.membersArea.CornerRadiusSetting',
}
